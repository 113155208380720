// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../node_modules/rescript/lib/es6/curry.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";

function waitForOptimize(retryOpt, param) {
  var retry = retryOpt !== undefined ? retryOpt : 0;
  var getExperiment = Belt_Option.flatMap(window.maker_optimize, (function (o) {
          return o.get;
        }));
  return new Promise((function (resolve, reject) {
                var match = window.maker_optimize;
                if (getExperiment !== undefined) {
                  return resolve(getExperiment);
                } else if (match !== undefined && retry < 12) {
                  setTimeout((function (param) {
                          waitForOptimize(retry + 1 | 0, undefined).then(function (response) {
                                resolve(response);
                              });
                        }), 42);
                  return ;
                } else {
                  return resolve(undefined);
                }
              }));
}

async function getConfigId(useConfigIdFromEmbedOpt, useConfigIdFromExperimentOpt, attributes, embedType) {
  var useConfigIdFromEmbed = useConfigIdFromEmbedOpt !== undefined ? useConfigIdFromEmbedOpt : true;
  var useConfigIdFromExperiment = useConfigIdFromExperimentOpt !== undefined ? useConfigIdFromExperimentOpt : true;
  var configIdFromEmbed = Belt_Option.keep(attributes.config, (function (param) {
          return useConfigIdFromEmbed;
        }));
  var getExperiment = useConfigIdFromExperiment ? await waitForOptimize(undefined, undefined) : undefined;
  var configIdFromExperiment = getExperiment !== undefined ? await Curry._4(getExperiment, undefined, embedType, undefined, undefined) : undefined;
  if (configIdFromExperiment !== undefined) {
    if (configIdFromExperiment === "empty") {
      return ;
    } else {
      return configIdFromExperiment;
    }
  } else if (configIdFromEmbed !== undefined) {
    return configIdFromEmbed;
  } else {
    return ;
  }
}

export {
  waitForOptimize ,
  getConfigId ,
}
/* No side effect */
